import Button from 'lego-framework/src/components/Button';
import Modal from 'lego-framework/src/containers/Modal';
import DeleteModal from '../component/DeleteModal';
import ConfirmModal from '../component/ConfirmModal';
import Tabs from 'lego-framework/src/containers/Tabs';
import Tab from 'lego-framework/src/containers/Tab';
import ClickOutside from 'lego-framework/src/directives/click-outside';
import FormInput from 'lego-framework/src/forms/FormInput';
import FormSelect from 'lego-framework/src/forms/FormSelect';
import FormDatePicker from 'lego-framework/src/forms/FormDatePicker';
import FormCheckbox from 'lego-framework/src/forms/FormCheckbox';
import FormTextArea from 'lego-framework/src/forms/FormTextArea';
import FormFileInput from 'lego-framework/src/forms/FormFileInput';
import DropdownButton from 'lego-framework/src/components/DropdownButton';
import DropDownItem from 'lego-framework/src/components/DropDownItem';
import FormAjaxSelect from 'lego-framework/src/forms/FormAjaxSelect';

import Form from 'lego-framework/src/forms/PostForm';
import SimpleForm from 'lego-framework/src/forms/SimpleForm';

import VueTable from 'lego-framework/src/tables/VueTable';
import VueTableCard from 'lego-framework/src/tables/VueTableCard';
import SimpleTable from 'lego-framework/src/tables/SimpleTable';

import ErrorIcon from 'lego-framework/src/svg-icons/ErrorIcon';
import SuccessIcon from 'lego-framework/src/svg-icons/SuccessIcon';
import DeleteAnimation from 'lego-framework/src/svg-icons/LoadingAnimation';

import * as validator from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

import EditButton from 'lego-framework/src/components/EditButton';
import DeleteButton from 'lego-framework/src/components/DeleteButton';
import SaveButton from 'lego-framework/src/components/SaveButton';
import NotificationsPlugin from 'lego-framework/src/notification';
import DatePicker from 'vue2-datepicker';

import AjaxView from 'lego-framework/src/ajax/AjaxView';
import FileInput from '../component/FileInput';
import LoadingAnimation from '../component/LoadingAnimation';
// Custom Tables //
import CustomVueTable from '../component/Tables/CustomVueTable';
import CustomSimpleTable from '../component/Tables/CustomSimpleTable';
import PlaneVueTable from '../component/Tables/PlaneVueTable';

import nameExists from '@/validators/nameExists';

export default {
    install : function (Vue) {
        Vue.use(ClickOutside);
        Vue.use(NotificationsPlugin);

        Vue.component('btn', Button);
        Vue.component('drop-btn', DropdownButton);
        Vue.component('drop-down-button', DropdownButton);
        Vue.component('drop-down-item', DropDownItem);
        Vue.component('edit-btn', EditButton);
        Vue.component('delete-btn', DeleteButton);
        Vue.component('save-btn', SaveButton);
        Vue.component('date-picker', DatePicker);
        Vue.component('tabs', Tabs);
        Vue.component('tab', Tab);
        Vue.component('modal', Modal);
        Vue.component('delete-modal', DeleteModal);
        Vue.component('confirm-modal', ConfirmModal);
        Vue.component('validated-file-input', FormFileInput);
        Vue.component('textarea-input', FormTextArea);
        Vue.component('file-input', FileInput);
        Vue.component('validated-ajax-vue-select', FormAjaxSelect);
        Vue.component('validated-date-picker', FormDatePicker);

        Vue.component('b-form', Form);
        Vue.component('s-form', SimpleForm);

        Vue.component('validated-input', FormInput);
        Vue.component('validated-select', FormSelect);
        Vue.component('validated-vue-select', FormSelect);
        Vue.component('checkbox-input', FormCheckbox);

        Vue.component('simple-table', SimpleTable);
        Vue.component('vue-table', VueTable);
        Vue.component('vue-table-card', VueTableCard);
        // Custom view table //
        Vue.component('custom-vue-table', CustomVueTable);
        Vue.component('plane-vue-table', PlaneVueTable);
        Vue.component('custom-simple-table', CustomSimpleTable);

        Vue.component('error-icon', ErrorIcon);
        Vue.component('success-icon', SuccessIcon);
        Vue.component('loading-animation', LoadingAnimation);
        Vue.component('delete-animation', DeleteAnimation);

        Vue.component('ajax-view', AjaxView);

        Vue.component('validation-provider', validator.ValidationProvider);

        Object.keys(rules).forEach(rule => {
            validator.extend(rule, {
                ...rules[rule], // copies rule configuration
                message : messages[rule] // assign message
            });
        });

        nameExists.install();
    }
};
