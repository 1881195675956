const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/';
} else {
    baseUrl = '/';
}

export default {
    auth : {
        login          : baseUrl + 'user/login/',
        status         : baseUrl + 'user/status/',
        logout         : baseUrl + 'user/logout/',
        ChangePassword : baseUrl + 'user/password-change/'
    },
    salesAdmin : {
        MasterData : {
            Group : {
                addEdit   : baseUrl + 'user/master-data/group-create/',
                list      : baseUrl + 'user/master-data/group-list/',
                delete    : baseUrl + 'user/master-data/group-delete/',
                vueSelect : baseUrl + 'user/master-data/group-vue-select/'
            }
        },
        orders : {
            changeStatus   : baseUrl + 'cart/entry-status-update/',
            entryVueSelect : baseUrl + 'cart/entry-status-select/',
            list           : baseUrl + 'cart/entry-list/',
            details        : baseUrl + 'cart/entry-details/'
        },
        invoices : {
            list    : baseUrl + 'cart/invoice-list/',
            print   : baseUrl + 'cart/invoice-print/',
            details : baseUrl + 'cart/invoice-details/'
        },
        refunds : {
            list         : baseUrl + 'cart/refund-list/',
            add          : baseUrl + 'cart/refund-create/',
            details      : baseUrl + 'cart/refund-details/',
            changeStatus : baseUrl + 'cart/refund-create/'
        },
        transaction : {
            list    : baseUrl + 'cart/entry-transaction-list/',
            details : baseUrl + 'cart/entry-transaction-details/'
        },
        review : {
            suspend  : baseUrl + 'catalogue/review-approve-or-reject/',
            list     : baseUrl + 'catalogue/review-list/',
            download : baseUrl + 'catalogue/review-excel-download/',
            details  : baseUrl + 'catalogue/review-details/'
        },
        customers : {
            add     : baseUrl + 'user/create/',
            suspend : baseUrl + 'user/active-or-suspend/',
            list    : baseUrl + 'user/list/',
            details : baseUrl + 'user/details/',

            addressList : baseUrl + 'user/address-list/',
            addressAdd  : baseUrl + 'user/address-create/',
            addressEdit : baseUrl + 'user/address-update/',

            countryOptions  : baseUrl + 'user/country/vue-select/',
            stateOptions    : baseUrl + 'user/state/vue-select/',
            districtOptions : baseUrl + 'user/district/vue-select/',

            orderList    : baseUrl + 'cart/entry-list/',
            invoiceList  : baseUrl + 'cart/invoice-list/',
            groupOptions : baseUrl + 'user/master-data/group-vue-select/'
        },
        reports : {
            orders      : baseUrl + 'cart/order-excel-download/',
            invoices    : baseUrl + 'cart/invoice-excel-download/',
            refund      : baseUrl + 'cart/refund-excel-download/',
            transaction : baseUrl + 'cart/transaction-excel-download/'
        },
        marketing : {
            promotions : {
                cartRule : {
                    list    : baseUrl + 'marketing/cart-rule-list/',
                    add     : baseUrl + 'marketing/cart-rule-create/',
                    edit    : baseUrl + 'marketing/cart-rule-update/',
                    details : baseUrl + 'marketing/cart-rule-details/',
                    delete  : baseUrl + 'marketing/cart-rule-delete/'
                },
                catalogueRule : {
                    list    : baseUrl + 'marketing/catalog-rule-list/',
                    add     : baseUrl + 'marketing/catalog-rule-create/',
                    details : baseUrl + 'marketing/catalog-rule-details/',
                    delete  : baseUrl + 'marketing/catalog-rule-delete/'
                }
            },
            emailMarketing : {
                emailTemplates : {
                    addEdit   : baseUrl + 'marketing/email-template-create/',
                    list      : baseUrl + 'marketing/email-template-list/',
                    details   : baseUrl + 'marketing/email-template-details/',
                    delete    : baseUrl + 'marketing/email-template-delete/',
                    vueSelect : baseUrl + 'marketing/email-template-vue-select/'
                },
                event : {
                    addEdit   : baseUrl + 'marketing/event-create/',
                    list      : baseUrl + 'marketing/event-list/',
                    details   : baseUrl + 'marketing/event-details/',
                    delete    : baseUrl + 'marketing/event-delete/',
                    vueSelect : baseUrl + 'marketing/event-vue-select/'
                },
                campaign : {
                    add     : baseUrl + 'marketing/campaign-create/',
                    edit    : baseUrl + 'marketing/campaign-update/',
                    list    : baseUrl + 'marketing/campaign-list/',
                    details : baseUrl + 'marketing/campaign-details/',
                    delete  : baseUrl + 'marketing/campaign-delete/'
                },
                newsLetterSubscription : {
                    addEdit : baseUrl + 'marketing/newsletter-update/',
                    list    : baseUrl + 'marketing/newsletter-list//',
                    details : baseUrl + 'marketing/newsletter-details/',
                    delete  : baseUrl + 'marketing/newsletter-delete/'
                }
            }
        }
    },
    catalogueAdmin : {
        attributeFamily : {
            add               : baseUrl + 'catalogue/family-create/',
            edit              : baseUrl + 'catalogue/family-update/',
            list              : baseUrl + 'catalogue/family-list/',
            delete            : baseUrl + 'catalogue/family-delete/',
            saveFamilyDetails : baseUrl + 'catalogue/family-details-save/',
            details           : baseUrl + 'catalogue/family-details/'
        },
        catalogue : {
            add                : baseUrl + 'catalogue/product-create/',
            edit               : baseUrl + 'catalogue/product-update/',
            list               : baseUrl + 'catalogue/product-list/',
            delete             : baseUrl + 'catalogue/product-delete/',
            saveProductDetails : baseUrl + 'catalogue/family-details-save/',
            details            : baseUrl + 'catalogue/product-details-list/',
            familyOptions      : baseUrl + 'catalogue/attribute-family/vue-select/',
            categoryOptions    : baseUrl + 'catalogue/category/vue-select/',
            addProductImage    : baseUrl + 'catalogue/product-image-create/',
            listProductImage   : baseUrl + 'catalogue/product-image-list/',
            deleteProductImage : baseUrl + 'catalogue/product-image-delete/',
            deleteDoc          : baseUrl + 'catalogue/product-doc-delete/',
            brandOptions       : baseUrl + 'catalogue/brand/vue-select/'
        }
    },
    warehouseAdmin : {
        request : {
            list                 : baseUrl + 'purchase/request-list/',
            history              : baseUrl + 'purchase/request-history/',
            delete               : baseUrl + 'purchase/request-delete/',
            saveRequestDetails   : baseUrl + 'purchase/request-create/',
            submitRequestDetails : baseUrl + 'purchase/request-submit/',
            details              : baseUrl + 'purchase/request-details/',
            item                 : {
                productOptions : baseUrl + 'catalogue/product-vue-select/',
                add            : baseUrl + 'purchase/request-item-create/',
                edit           : baseUrl + 'purchase/request-item-update/',
                list           : baseUrl + 'purchase/family-list/',
                delete         : baseUrl + 'purchase/request-item-delete/'
            }
        },
        purchaseEntry : {
            list        : baseUrl + 'purchase/entry-list/',
            submit      : baseUrl + 'purchase/entry-warehouse-submit/',
            searchOrder : baseUrl + 'purchase/entry-order-details/',
            save        : baseUrl + 'purchase/entry-create/',
            details     : baseUrl + 'purchase/entry-details/'
        },
        purchaseReturn : {
            list            : baseUrl + 'purchase/return-list/',
            submit          : baseUrl + 'purchase/return-submit/',
            returnEntryItem : baseUrl + 'purchase/return-create/',
            deleteEntryItem : baseUrl + 'purchase/return-delete/',
            details         : baseUrl + 'purchase/return-details/'
        },
        vendorOptions : baseUrl + 'user/vendor-vue-select/'
    },
    purchaseAdmin : {
        request : {
            vendorOptions : baseUrl + 'user/vendor-vue-select/',
            list          : baseUrl + 'purchase/request-order-list/',
            accept        : baseUrl + 'purchase/order-create/',
            reject        : baseUrl + 'purchase/request-reject/',
            details       : baseUrl + 'purchase/request-order-details/'
        },
        purchaseOrder : {
            submitOrder : baseUrl + 'purchase/order-approve/',
            list        : baseUrl + 'purchase/order-list/',
            details     : baseUrl + 'purchase/order-details/'
        },
        purchaseReturn : {
            submit  : baseUrl + 'purchase/return-purchase-submit/',
            list    : baseUrl + 'purchase/return-purchase-list/',
            details : baseUrl + 'purchase/return-purchase-details/'
        },
        purchaseEntry : {
            list    : baseUrl + 'purchase/entry-purchase-list/',
            submit  : baseUrl + 'purchase/entry-purchase-approve/',
            details : baseUrl + 'purchase/entry-purchase-details/'
        },
        purchaseOrderFA : {
            submitOrder : baseUrl + 'purchase/order-submit/',
            list        : baseUrl + 'purchase/order-finance-approved-list/',
            details     : baseUrl + 'purchase/order-details/'
        },
        history       : baseUrl + 'purchase/order-history/',
        vendorOptions : baseUrl + 'user/vendor-vue-select/',
        entryHistory  : baseUrl + 'purchase/entry-history/',
        returnHistory : baseUrl + 'purchase/return-history/'
    },
    financeAdmin : {
        purchaseOrder : {
            rejectOrder  : baseUrl + 'purchase/order-finance-approve/',
            approveOrder : baseUrl + 'purchase/order-finance-approve/',
            list         : baseUrl + 'purchase/order-finance-list/',
            details      : baseUrl + 'purchase/order-finance-details/'
        },
        purchaseReturn : {
            submit           : baseUrl + 'purchase/return-finance-submit/',
            list             : baseUrl + 'purchase/return-finance-list/',
            details          : baseUrl + 'purchase/return-finance-details/',
            addAttachment    : baseUrl + 'purchase/payment-document-create/',
            listAttachment   : baseUrl + 'purchase/return-payment-document-list/',
            deleteAttachment : baseUrl + 'purchase/payment-document-delete/'
        },
        purchaseEntry : {
            submit           : baseUrl + 'purchase/entry-finance-submit/',
            list             : baseUrl + 'purchase/entry-finance-list/',
            details          : baseUrl + 'purchase/entry-finance-details/',
            addAttachment    : baseUrl + 'purchase/payment-document-create/',
            listAttachment   : baseUrl + 'purchase/payment-document-list/',
            deleteAttachment : baseUrl + 'purchase/payment-document-delete/'
        },
        history       : baseUrl + 'purchase/order-history/',
        vendorOptions : baseUrl + 'user/vendor-vue-select/',
        entryHistory  : baseUrl + 'purchase/entry-history/',
        returnHistory : baseUrl + 'purchase/return-history/'
    },
    hrAdmin : {
        staff : {
            suspendUser      : baseUrl + 'user/active-or-suspend/',
            list             : baseUrl + 'user/staff-list/',
            roleOptions      : baseUrl + 'user/role/options/',
            add              : baseUrl + 'user/staff-create/',
            details          : baseUrl + 'user/staff-details/',
            staffDetailsSave : baseUrl + 'user/staff-details-create/',
            familyOptions    : baseUrl + 'catalogue/attribute-family/vue-select/',

            ProfileImage            : baseUrl + 'user/staff-profile-image/',
            addProfileImage         : baseUrl + 'user/staff-profile-image-update/',
            staffProfileImageDelete : baseUrl + '',

            staffDocList   : baseUrl + 'user/kyc-list/',
            staffDocAdd    : baseUrl + 'user/kyc-create/',
            staffDocDelete : baseUrl + 'user/kyc-delete/',

            countryOptions    : baseUrl + 'user/country/vue-select/',
            stateOptions      : baseUrl + 'user/state/vue-select/',
            districtOptions   : baseUrl + 'user/district/vue-select/',
            bloodGroupOptions : baseUrl + 'user/blood_group/vue-select/',
            departmentOptions : baseUrl + 'user/department/vue-select/'

        },
        attendance : {
            attendanceList   : baseUrl + 'user/staff-report-list/',
            attendanceUpdate : baseUrl + 'user/attendance-create/'
        },
        reports : {
            attendanceReportDetails : baseUrl + 'user/attendance-report/',
            attendanceDownload      : baseUrl + 'user/attendance-report-download/'
        }
    },
    notifications : baseUrl + 'user/notifications/'
};
