<template>
    <div class="row ld-over pl-3 pr-3" :class="[{'running': dataLoading}]">

        <div class="ld ld-ring ld-spin" v-if="dataLoading"></div>

        <div class="col p-0">
            <div class="loading" v-show="error">
                <slot name="error">
                    <div class="alert alert-danger">
                        <error-icon/>
                        Loading Data Failed.
                    </div>
                </slot>
            </div>

            <vuetable ref="vuetable" class="mb-0 order-with-arrow table-borderless customtablehead" pagination-path
                      :api-url="url" :per-page="perPageValue"
                      :reactive-api-url="true" :fields="fields" @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="showLoadingAnimation"
                      @vuetable:load-success="hideLoadingAnimation" @vuetable:load-error="setError"
                      :query-params="makeQueryParams" v-show="!error" :load-on-start="loadOnStart">
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope"/>
                </template>
            </vuetable>

            <vuetable-pagination-bootstrap :next-icon="nextIcon"
                                           :prev-icon="prevIcon"
                                           v-show="showPagination"
                                           class="mt-4 fl-eqh-c mb-4" ref="pagination"
                                           @vuetable-pagination:change-page="onChangePage"/>

        </div>
    </div>
</template>

<script>
import VueTableComponentMixin from '@lego/tables/VueTableComponentMixin';

export default {
    name    : 'vue-table',
    extends : VueTableComponentMixin,
    props   : {
        pageNumber : { Type : Boolean, default : true },
        title      : { type : String, required : false, default : '' }
    }
};
</script>
