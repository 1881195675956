<template>
    <div class="row ld-over" :class="[{'running': dataLoading}]">

        <div class="ld ld-ring ld-spin" v-if="dataLoading"></div>

        <div class="col">
            <div class="fl-te-c mb-3">
                <h4><span v-if="title.length > 0">{{ title }}</span></h4>
                <div class="fl-eq-t">
                    <validated-input class="mb-0 vinput"
                                     placeholder="Search" v-model="search" v-show="showSearchBox">
                        <template #append>
                            <span><i class="fa fa-search" aria-hidden="true"></i></span>
                        </template>
                    </validated-input>

                    <slot name="filters"></slot>
                </div>

            </div>

            <div class="loading" v-show="error">
                <slot name="error">
                    <div class="alert alert-danger">
                        <error-icon/>
                        Loading Data Failed.
                    </div>
                </slot>
            </div>

            <vuetable ref="vuetable" class="mb-0 bs-4 order-with-arrow card table-borderless customtablehead" pagination-path
                      :api-url="url" :per-page="perPageValue"
                      :reactive-api-url="true" :fields="fields" @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="showLoadingAnimation"
                      @vuetable:load-success="hideLoadingAnimation" @vuetable:load-error="setError"
                      :query-params="makeQueryParams" v-show="!error" :load-on-start="loadOnStart">
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope"/>
                </template>
            </vuetable>
            <div class="fl-te-c">
                <vuetable-pagination-bootstrap :next-icon="nextIcon"
                                               :prev-icon="prevIcon"
                                               v-show="showPagination"
                                               class="mt-4" ref="pagination"
                                               @vuetable-pagination:change-page="onChangePage"/>
                <div class="" v-if="pageNumber">
                    <span class="text-muted text-small ml-2 mr-1 mb-2">{{ from }}-{{ to }} of {{ total }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueTableComponentMixin from '@lego/tables/VueTableComponentMixin';

export default {
    name    : 'vue-table',
    extends : VueTableComponentMixin,
    props   : {
        pageNumber : { Type : Boolean, default : true },
        title      : { type : String, required : false, default : '' }
    }
};
</script>
