import guards from './guards';

const ChangePassword = () => '../views/Auth/ChangePassword';
const Layout = () => import('../layouts/MainLayout');
const Home = () => import('../views/HomePages/Dashboard');
const Notifications = () => import('../views/Notifications/Notification');

// Catalogue Section
const DataStructure = () => import('../views/CatalogueAdmin/DataStructure/DataStructure');
const CatalogueDetails = () => import('../views/CatalogueAdmin/Catalog/CatalogDetailsPage');
const Catalogue = () => import('../views/CatalogueAdmin/Catalog/CatalogueList');

const StaffsListing = () => import('../views/HRAdmin/Staff/StaffsListing');
const DailyAttendance = () => import('../views/HRAdmin/Attendance/DailyAttendance');
const AttendanceReportPage = () => import('../views/HRAdmin/Attendance/AttendanceReportPage');
const StaffDetailsPage = () => import('../views/HRAdmin/Staff/StaffDetailsPage');

// Warehouse Admin
const RequestListWareHouse = () => import('../views/WarehouseAdmin/PurchaseOrder/Request/ListingComponent');
const RequestListDetailsWareHouse = () => import('../views/WarehouseAdmin/PurchaseOrder/Request/DetailsComponent');

const WAEntryList = () => import('../views/WarehouseAdmin/PurchaseEntry/PurchaseEntry/ListingComponent');
const WAEntryAdd = () => import('../views/WarehouseAdmin/PurchaseEntry/PurchaseEntry/AddComponent');
const WAEntryDetails = () => import('../views/WarehouseAdmin/PurchaseEntry/PurchaseEntry/DetailsViewOnlyPage');

const WAReturnList = () => import('../views/WarehouseAdmin/PurchaseReturn/ListingComponent');
const WAReturnAdd = () => import('../views/WarehouseAdmin/PurchaseReturn/Components/AddComponent');
const WAReturnDetails = () => import('../views/WarehouseAdmin/PurchaseReturn/DetailsViewPage');

// Purchase Admin
const PAOrderList = () => import('../views/PurchaseAdmin/PurchaseOrder/PurchaseOrder/ListingComponent');
const PAOrderDetails = () => import('../views/PurchaseAdmin/PurchaseOrder/PurchaseOrder/DetailsComponent');
const PAOrderHistory = () => import('../views/PurchaseAdmin/PurchaseOrder/PurchaseOrder/Components/OrderHistory');
const PAOrderFADetails = () => import('../views/PurchaseAdmin/PurchaseOrder/PurchaseOrder/Components/SendToVendor');
const PAOrderFAHistory = () => import('../views/PurchaseAdmin/PurchaseOrder/PurchaseOrder/Components/OrderHistoryFA');
const PAOrderRequestList = () => import('../views/PurchaseAdmin/PurchaseOrder/Requests/ListingComponent');
const PAOrderRequestDetails = () => import('../views/PurchaseAdmin/PurchaseOrder/Requests/DetailsComponent');

const PAEntryList = () => import('../views/PurchaseAdmin/PurchaseEntry/PurchaseEntry/ViewPage');
const PAEntryDetails = () => import('../views/PurchaseAdmin/PurchaseEntry/PurchaseEntry/DetailsComponent');
const PAEntryHistory = () => import('../views/PurchaseAdmin/PurchaseEntry/PurchaseEntry/TableComponents/EntryHistory');
const PAReturnList = () => import('../views/PurchaseAdmin/PurchaseReturn/ViewPage');
const PAReturnDetails = () => import('../views/PurchaseAdmin/PurchaseReturn/DetailsPage');
const PAReturnHistory = () => import('../views/PurchaseAdmin/PurchaseReturn/ReturnHistory');

// Finance Admin
const FAOrderList = () => import('../views/FinanceAdmin/PurchaseOrder/PurchaseOrder/ListingComponent');
const FAOrderDetails = () => import('../views/FinanceAdmin/PurchaseOrder/PurchaseOrder/DetailsComponent');
const FAOrderHistory = () => import('../views/FinanceAdmin/PurchaseOrder/PurchaseOrder/Components/OrderHistory');
const FAEntryList = () => import('../views/FinanceAdmin/PurchaseEntry/PurchaseEntry/ViewPage');
const FAEntryDetails = () => import('../views/FinanceAdmin/PurchaseEntry/PurchaseEntry/DetailsComponent');
const FAEntryHistory = () => import('../views/FinanceAdmin/PurchaseEntry/PurchaseEntry/TableComponents/EntryHistory');
const FAReturnList = () => import('../views/FinanceAdmin/PurchaseReturn/ViewPage');
const FAReturnDetails = () => import('../views/FinanceAdmin/PurchaseReturn/DetailsPage');
const FAReturnHistory = () => import('../views/FinanceAdmin/PurchaseReturn/Components/ReturnHistory');

// Sales Admin
const SAOrdersList = () => import('../views/SalesAdmin/Sales/Orders/ListingComponent');
const SAOrderDetails = () => import('../views/SalesAdmin/Sales/Orders/DetailsComponent');
const SAInvoicesList = () => import('../views/SalesAdmin/Sales/Invoices/ListingComponent');
const SAInvoiceDetails = () => import('../views/SalesAdmin/Sales/Invoices/DetailsComponent');
const SARefundsList = () => import('../views/SalesAdmin/Sales/Refunds/ListingComponent');
const SARefundDetails = () => import('../views/SalesAdmin/Sales/Refunds/DetailsComponent');
const SATransactionsList = () => import('../views/SalesAdmin/Sales/Transaction/ListingComponent');
const SATransactionDetails = () => import('../views/SalesAdmin/Sales/Transaction/DetailsComponent');
const SAGroup = () => import('../views/SalesAdmin/Sales/MasterData/Group');
const SACustomer = () => import('../views/SalesAdmin/Sales/Customers/ListingComponent');
const SACustomerDetails = () => import('../views/SalesAdmin/Sales/Customers/DetailsComponent');
const SAReview = () => import('../views/SalesAdmin/Sales/Customers/Review/ListingComponent');
const SAReviewDetails = () => import('../views/SalesAdmin/Sales/Customers/Review/DetailsComponent');

const SAOrderReports = () => import('../views/SalesAdmin/Sales/Reports/OrderReports');
const SAInvoiceReports = () => import('../views/SalesAdmin/Sales/Reports/InvoiceReports');
const SARefundReports = () => import('../views/SalesAdmin/Sales/Reports/RefundReports');
const SATransactionReports = () => import('../views/SalesAdmin/Sales/Reports/TransactionReports');

const SAMarketingPromotions = () => import('../views/SalesAdmin/Marketing/PromotionViewPage');
const SACatalogueRuleDetails = () => import('../views/SalesAdmin/Marketing/CatalogueRules/DetailsComponent');
const SACartRuleDetails = () => import('../views/SalesAdmin/Marketing/CartRules/DetailsComponent');
const SAEmailMarketing = () => import('../views/SalesAdmin/Marketing/EmailMarketingViewPage');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        {
            path      : '/',
            name      : 'Home',
            component : Home
        },
        {
            path      : '/notifications/',
            name      : 'Notifications',
            component : Notifications
        },
        // CATALOGUE ADMIN
        {
            path      : '/data-structure/',
            name      : 'DataStructure',
            component : DataStructure
        },
        {
            path      : '/catalogue/',
            name      : 'Catalogue',
            component : Catalogue
        },
        {
            path      : '/catalogue/:id/details/',
            name      : 'CatalogueDetails',
            component : CatalogueDetails
        },
        {
            path      : '/change-password/',
            name      : 'ChangePassword',
            component : ChangePassword
        },

        //    H R ADMIN SECTION

        {
            path      : '/staff-listing/',
            name      : 'StaffListing',
            component : StaffsListing
        },
        {
            path      : '/attendance/',
            name      : 'Attendance',
            component : DailyAttendance
        },
        {
            path      : '/attendance/:id/report/',
            name      : 'AttendanceDetailsPage',
            component : AttendanceReportPage
        },
        {
            path      : '/staff/:id/details/',
            name      : 'StaffDetailsPage',
            component : StaffDetailsPage
        },

        //    WAREHOUSE ADMIN SECTION

        {
            path      : '/warehouse/order/request-list/:id/details/',
            name      : 'RequestListDetailsWareHouse',
            component : RequestListDetailsWareHouse
        },
        {
            path      : '/warehouse/order/request-list/',
            name      : 'RequestListWareHouse',
            component : RequestListWareHouse
        },
        {
            path      : '/warehouse/entry/list/',
            name      : 'WAEntryList',
            component : WAEntryList
        },
        {
            path      : '/warehouse/entry/:id/details/',
            name      : 'WAEntryDetails',
            component : WAEntryDetails
        },
        {
            path      : '/warehouse/entry/add/',
            name      : 'WAEntryAdd',
            component : WAEntryAdd
        },
        {
            path      : '/warehouse/entry/:id/add/',
            name      : 'WAEntryAdd',
            component : WAEntryAdd
        },
        {
            path      : '/warehouse/return/list/',
            name      : 'WAReturnList',
            component : WAReturnList
        },
        {
            path      : '/warehouse/return/add/',
            name      : 'WAReturnAdd',
            component : WAReturnAdd
        },
        {
            path      : '/warehouse/return/:id/details/',
            name      : 'WAReturnDetails',
            component : WAReturnDetails
        },

        //    PURCHASE ADMIN SECTION

        {
            path      : '/purchase/order/request-list/',
            name      : 'PAOrderRequestList',
            component : PAOrderRequestList
        },
        {
            path      : '/purchase/order/request-list/:id/details/',
            name      : 'PAOrderRequestDetails',
            component : PAOrderRequestDetails
        },
        {
            path      : '/purchase/order/list/',
            name      : 'PAOrderList',
            component : PAOrderList
        },
        {
            path      : '/purchase/order-fa/:id/details/',
            name      : 'PAOrderFADetails',
            component : PAOrderFADetails
        },
        {
            path      : '/purchase/order/:id/details/',
            name      : 'PAOrderDetails',
            component : PAOrderDetails
        },
        {
            path      : '/purchase/order/:id/history/',
            name      : 'PAOrderHistory',
            component : PAOrderHistory
        },
        {
            path      : '/purchase/order/:id/fa-history/',
            name      : 'PAOrderFAHistory',
            component : PAOrderFAHistory
        },
        {
            path      : '/purchase/entry/list/',
            name      : 'PAEntryList',
            component : PAEntryList
        },
        {
            path      : '/purchase/entry/:id/details/',
            name      : 'PAEntryDetails',
            component : PAEntryDetails
        },
        {
            path      : '/purchase/entry/:id/history/',
            name      : 'PAEntryHistory',
            component : PAEntryHistory
        },
        {
            path      : '/purchase/return/:id/history/',
            name      : 'PAReturnHistory',
            component : PAReturnHistory
        },
        {
            path      : '/purchase/return/list/',
            name      : 'PAReturnList',
            component : PAReturnList
        },
        {
            path      : '/purchase/return/:id/details/',
            name      : 'PAReturnDetails',
            component : PAReturnDetails
        },

        // FINANCE ADMIN SECTION
        {
            path      : '/finance/order/list/',
            name      : 'FAOrderList',
            component : FAOrderList
        },
        {
            path      : '/finance/order/:id/details/',
            name      : 'FAOrderDetails',
            component : FAOrderDetails
        },
        {
            path      : '/finance/order/:id/history/',
            name      : 'FAOrderHistory',
            component : FAOrderHistory
        },
        {
            path      : '/finance/entry/list/',
            name      : 'FAEntryList',
            component : FAEntryList
        },
        {
            path      : '/finance/entry/:id/details/',
            name      : 'FAEntryDetails',
            component : FAEntryDetails
        },
        {
            path      : '/finance/entry/:id/history/',
            name      : 'FAEntryHistory',
            component : FAEntryHistory
        },
        {
            path      : '/finance/return/:id/history/',
            name      : 'FAReturnHistory',
            component : FAReturnHistory
        },
        {
            path      : '/finance/return/list/',
            name      : 'FAReturnList',
            component : FAReturnList
        },
        {
            path      : '/finance/return/:id/details/',
            name      : 'FAReturnDetails',
            component : FAReturnDetails
        },

        // Sales ADMIN SECTION
        {
            path      : '/sales/orders/list/',
            name      : 'SAOrdersList',
            component : SAOrdersList
        },
        {
            path      : '/sales/order/:id/details/',
            name      : 'SAOrderDetails',
            component : SAOrderDetails
        },
        {
            path      : '/sales/invoices/list/',
            name      : 'SAInvoicesList',
            component : SAInvoicesList
        },
        {
            path      : '/sales/invoice/:id/details/',
            name      : 'SAInvoiceDetails',
            component : SAInvoiceDetails
        },
        {
            path      : '/sales/refunds/list/',
            name      : 'SARefundsList',
            component : SARefundsList
        },
        {
            path      : '/sales/refund/:id/details/',
            name      : 'SARefundDetails',
            component : SARefundDetails
        },
        {
            path      : '/sales/transactions/list/',
            name      : 'SATransactionsList',
            component : SATransactionsList
        },
        {
            path      : '/sales/transaction/:id/details/',
            name      : 'SATransactionDetails',
            component : SATransactionDetails
        },
        {
            path      : '/sales/invoice/:id/details/',
            name      : 'SAInvoiceDetails',
            component : SAInvoiceDetails
        },
        {
            path      : '/sales/order/report/',
            name      : 'SAOrderReports',
            component : SAOrderReports
        },
        {
            path      : '/sales/invoice/report/',
            name      : 'SAInvoiceReports',
            component : SAInvoiceReports
        },
        {
            path      : '/sales/refund/report/',
            name      : 'SARefundReports',
            component : SARefundReports
        },
        {
            path      : '/sales/transaction/report/',
            name      : 'SATransactionReports',
            component : SATransactionReports
        },
        {
            path      : '/sales/master-data/group/',
            name      : 'SAGroup',
            component : SAGroup
        },
        {
            path      : '/sales/customer/list/',
            name      : 'SACustomer',
            component : SACustomer
        },
        {
            path      : '/sales/review/list/',
            name      : 'SAReview',
            component : SAReview
        },
        {
            path      : '/sales/review/:id/details/',
            name      : 'SAReview',
            component : SAReviewDetails
        },
        {
            path      : '/sales/customer/:id/details/',
            name      : 'SAReview',
            component : SACustomerDetails
        },
        {
            path      : '/sales/marketing/promotions/',
            name      : 'SAMarketingPromotions',
            component : SAMarketingPromotions
        },
        {
            path      : '/sales/email-marketing/',
            name      : 'SAEmailMarketing',
            component : SAEmailMarketing
        },
        {
            path      : '/sales/email-marketing/:id/catalogue-rule-details/',
            name      : 'SACatalogueRuleDetails',
            component : SACatalogueRuleDetails
        },
        {
            path      : '/sales/email-marketing/:id/cart-rule-details/',
            name      : 'SACartRuleDetails',
            component : SACartRuleDetails
        }
    ]
};
